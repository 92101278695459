<template lang="pug">
    v-container(:class="classes")
        loader(v-if="$root.loader")
        template(v-else)
            v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                color="error"
                v-model="error")
                    | {{ errorText }}
                    v-btn(flat, dark, @click.native="error = false") {{ $t('cerrar') }}
            div.full-width(v-if="!activeNetwork && !Object.keys(currentNetwork).length")
                v-layout(row wrap)
                    v-flex(xs12)
                        figure
                            img(:src="registerUrl" alt="Wappid")
                    v-flex(xs12 v-html="notfoundtext")
            template(v-else)
                template(v-if="!isNetwork(['juicioaexpresidentes', 'rodolfistas'])")
                    .register-poster
                        v-layout(register-poster row wrap)
                            v-flex(xs11)
                                figure
                                    img(:src="registerUrl" alt="Wappid" @click="$router.push({path:'/login'})")
                            v-flex(v-if="activeNetwork" xs12 v-html="logintext")
                            v-flex(xs11 v-if="!activeNetwork && Object.keys(currentNetwork).length" v-html="currentNetwork.logintext")
                    .register-content(v-if="loadPage")
                    .register-content(v-else)
                        v-stepper(v-model="register")
                            v-stepper-header
                                v-stepper-step(step="1" :complete="register > 1") {{ $t('Paso 1') }}
                                v-stepper-step(step="2" :complete="register > 2") {{ $t('Paso 2') }}
                                v-stepper-step(step="3") {{ $t('Paso 3') }}
                            v-stepper-items
                                v-stepper-content(step="1")
                                    br
                                    br
                                    h2.mt-4.secondary--text(v-if="isNetwork(['joaquingobernador'])") {{ $t('Registrate acá si aún no tenés cuenta')}} 
                                        template(v-if="!activeNetwork") {{$t('en la red de apoyo')}} {{ this.currentNetwork.name }}
                                    h2.mt-4.secondary--text(v-else-if="isNetwork(['sumatealfr'])") 
                                        | {{ $t('Si todavía no tenes cuenta')}} 
                                        template(v-if="!activeNetwork") {{$t('en la red de apoyo')}} {{ this.currentNetwork.name }},&nbsp;
                                        | {{ $t('registrate acá') }}
                                    h2.mt-4.secondary--text(v-else) {{ $t('Regístrate')}} 
                                        template(v-if="!activeNetwork") {{$t('en la red')}} {{ this.currentNetwork.name }}
                                    v-layout(row wrap)
                                        template(v-if="!initialData")
                                            v-flex(xs12)
                                                h3(v-if="hasMexico") {{$t('Ingresa tu teléfono o correo electrónico o ID y una contraseña')}}
                                                h3(v-else) {{$t('Ingresa tu teléfono o correo electrónico y una contraseña')}}
                                            template(v-if="!activeNetwork")
                                                v-flex(xs12)
                                                    v-text-field(v-if="hasMexico" v-model.trim="step.username" :label="$t('Teléfono o correo electrónico o ID')" type="text" required)
                                                    v-text-field(v-else v-model.trim="step.username" :label="$t('Teléfono o correo electrónico')" type="text" required)
                                                v-flex(xs12 v-if="['email', 'phone', 'username'].includes(validateUser)")
                                                    v-text-field(v-if="validateUser == 'email'" v-model.trim="step.rusername", :label="$t('Confirma tu correo electrónico')", type="text", required)
                                                    v-text-field(v-if="validateUser == 'phone'" v-model.trim="step.rusername", :label="$t('Confirma tu teléfono')", type="text", required)
                                                    v-text-field(v-if="hasMexico && validateUser == 'username'" v-model.trim="step.rusername", :label="$t('Confirma tu ID')" type="text" required)
                                            template(v-else)
                                                v-flex(xs12)
                                                    v-text-field(v-model.trim="step.email", :label="$t('Correo electrónico')", type="email", required)
                                                v-flex(xs12)
                                                    v-text-field(v-model.trim="step.remail", :label="$t('Confirma tu correo electrónico')", type="email", required)
                                            v-flex(xs12)
                                                v-text-field(
                                                    v-model="step.password" 
                                                    :label="$t('Contraseña')"
                                                    required
                                                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                    :append-icon-cb="() => (e1 = !e1)"
                                                    :type="e1 ? 'password' : 'text'")
                                                small {{$t('La contraseña debe contener números y letras')}}
                                            v-divider.mt-4
                                            v-flex(xs12 align-center v-if="!activeNetwork")
                                                v-layout.pa-3(align-center hide-details)
                                                    v-checkbox(v-model="terms")
                                                    p.ml-2
                                                        | {{$t('Acepto')}} 
                                                        a(@click="termsModal=true") {{$t('el tratamiento de mis datos personales')}} 
                                                        | {{$t('según el presente aviso de privacidad')}}.
                                                        | {{$t('Acepto el uso del sitio web conforme a los presentes términos y condiciones')}}.
                                                        | {{$t('y dar luego en el botón SIGUIENTE')}}
                                            v-flex(xs12 v-if="terms")
                                                .btns.btns-center
                                                    v-btn(color="primary" depressed @click.native="check" :loading="loadCheck") {{ $t('Siguiente') }}
                                        v-divider.mt-4
                                        template(v-if="initialData")
                                            template(v-if="!activeNetwork")
                                                template(v-if="validateUser == 'email'")
                                                    v-flex(xs12)
                                                        v-text-field(v-model.trim="step.email", :label="$t('Teléfono o correo electrónico')", type="text", required)
                                                    v-flex(xs12)
                                                        v-text-field(v-model.trim="step.remail", :label="$t('Confirma tu correo electrónico')", type="text", required)
                                            template(v-else)
                                                v-flex(xs12)
                                                    v-text-field(v-model.trim="step.email", :label="$t('Correo electrónico')", type="email", required)
                                                v-flex(xs12)
                                                    v-text-field(v-model.trim="step.remail", :label="$t('Confirma tu correo electrónico')", type="email", required)
                                            v-flex(xs12)
                                                v-text-field(
                                                    v-model="step.password" 
                                                    :label="$t('Contraseña')"
                                                    required
                                                    :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                                    :append-icon-cb="() => (e1 = !e1)"
                                                    :type="e1 ? 'password' : 'text'")
                                                small {{$t('La contraseña debe contener números y letras')}}
                                            v-flex(xs12)
                                                v-text-field(v-model='step.names', :label='$t("Nombre(s)")', required)
                                            v-flex(xs12)
                                                v-text-field(v-model='step.surname', :label='$t("Primer apellido")', required)
                                            v-flex(xs12)
                                                v-text-field(v-model='step.surname2' :label='$t("Segundo apellido")')
                                            v-flex(xs12 v-if="isAvailable('countryid')")
                                                v-select(
                                                    v-model="step.countryid"
                                                    :items='country',
                                                    item-value="uuid",
                                                    item-text="name", 
                                                    :label='$t("País")', 
                                                    append-icon="keyboard_arrow_down", 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    required
                                                    @input="step.stateid='',state=[],step.cityid='',city=[],step.zones=[],zones=[],zone=-1,fetchState()"
                                                )
                                            v-flex(xs12 v-if="isAvailable('stateid')")
                                                v-select(
                                                    v-model="step.stateid"
                                                    :items='state',
                                                    item-value="uuid",
                                                    item-text="name", 
                                                    :label='$t("Estado/Departamento/Provincia")', 
                                                    append-icon="keyboard_arrow_down", 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    required
                                                    @input="step.cityid='',city=[],step.zones=[],zones=[],zonei=-1,fetchCity()"
                                                )
                                            v-flex(xs12 v-if="isAvailable('cityid')")
                                                v-select(
                                                    v-model="step.cityid"
                                                    :items='city',
                                                    item-value="uuid",
                                                    item-text="name", 
                                                    :label='$t("Ciudad/Municipio")', 
                                                    append-icon="keyboard_arrow_down", 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    required
                                                    @input="step.zones=[],zones=[],zonei=-1,fetchZone()"
                                                )
                                            template(v-if="zones.length && isAvailable('zones')")
                                                v-flex(xs12 v-for="(zone, i) in zones" :key="'z'+i")
                                                    v-select(
                                                        v-model="step.zones[i]"
                                                        :items='zone',
                                                        item-value="uuid",
                                                        item-text="name", 
                                                        :label='zone[0].division', 
                                                        append-icon="keyboard_arrow_down", 
                                                        autocomplete
                                                        :filter="vFilter"
                                                        :required="isRequired('zones')"
                                                        @change="zonei=i"
                                                        @input="fetchZone"
                                                    )
                                            v-flex(xs12)
                                                v-text-field(:prefix="phone" v-model.trim='step.phone', :label='$t("Celular (WhatsApp)")', required)
                                            v-flex(xs12 v-if="validateUser == 'phone'")
                                                v-text-field(v-model.trim="step.optional_email" :label="$t('Correo electrónico (opcional)')", type="email")
                                            v-flex(xs12 v-if="isAvailable('gender')")
                                                v-select(
                                                    v-model="step.gender"
                                                    :items='gender', 
                                                    item-value="id", 
                                                    item-text="text" 
                                                    :label='$t("Género")', 
                                                    append-icon="keyboard_arrow_down", 
                                                    autocomplete
                                                    :filter="vFilter"
                                                    required
                                                )
                                            v-flex(xs12 v-if="isAvailable('birthdate')")
                                                v-dialog(ref='dialogBirthdate' persistent v-model='modalBirthdate' lazy full-width width='290px', :return-value.sync='step.birthdate')
                                                    v-text-field(slot='activator' :label='$t("Fecha de nacimiento")' v-model='step.birthdate' readonly hide-details)
                                                    v-date-picker(
                                                        :locale="$root.currentLanguage" 
                                                        v-model='step.birthdate' 
                                                        ref="birthdate" 
                                                        scrollable
                                                        :min="minBirthdateDate"
                                                        :max="maxBirthdateDate"
                                                    )
                                                        v-spacer
                                                        v-btn(flat color='primary' @click='modalBirthdate = false') {{$t('Cancelar')}}
                                                        v-btn(flat color='primary' @click='$refs.dialogBirthdate.save(step.birthdate)') {{$t('OK')}}
                                            v-flex(xs12 v-if="(validateUser == 'username' && hasMexico) || isAvailable('document')")
                                                v-text-field(v-model='step.document' :label='$t("Número de documento de identificación")')
                                            v-flex(xs12 v-show="false")
                                                v-text-field(v-model="step.code" :label="$t('Código de invitación')")

                                            v-flex(xs12 v-for="(c, i) in customForm" :key="'c'+i")
                                                v-text-field(v-if="c.type == 1" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                                v-text-field(multi-line v-if="c.type == 5" v-model="step.custom[c.uuid]" :label="$t(c.placeholder)" type="text" :required="c.required")
                                                template(v-if="c.type == 2")
                                                    label.d-block.mb-1 {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                                    v-checkbox(v-for="(opt, i) in c.options" v-model="step.custom[c.uuid]" :key="'opt'+i" :label="(typeof opt == 'string' ? opt : opt.option)" :value="(typeof opt == 'string' ? opt : opt.option)")
                                                template(v-if="c.type == 4")
                                                    v-select(clearable v-if="c.depends == null ? true : !c.depends.length" v-model="step.custom[c.uuid]" :return-object="true" :items="c.options" item-value="uuid" item-text="option" :label="c.placeholder" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" :required="c.required == 1" @input="computedDepends(c, $event)")
                                                    v-select(clearable v-else v-model="step.custom[c.uuid]" :items="c.hasOwnProperty('coptions') ? c.coptions : []" :required="c.required" item-value="uuid" item-text="option" :label="c.placeholder" :return-object="true" autocomplete :filter="vFilter" append-icon="keyboard_arrow_down" @input="computedDepends(c, $event)")
                                                v-radio-group(v-if="c.type == 3" v-model="step.custom[c.uuid]")
                                                    label {{ c.placeholder }}{{c.required ? '&bull;':''}}
                                                    v-radio(v-for="(opt, i) in c.options" :key="'opt'+i" :value="(typeof opt == 'string' ? opt : opt.option)" :label="(typeof opt == 'string' ? opt : opt.option)")

                                            //- Grupo de WhatsApp
                                            v-flex(xs12 v-if="wpgroupEnabled && wpgroups.length")
                                                v-select(
                                                    v-model="step.wpgroup"
                                                    :items='wpgroups'
                                                    item-value="uuid" 
                                                    item-text="name"
                                                    :label='$t("Grupo de WhatsApp")'
                                                    append-icon="keyboard_arrow_down" 
                                                    autocomplete
                                                    :filter="vFilter"
                                                )

                                            //- Campos visibles (Rodolfistas)
                                            template(v-if="currentNetwork.isRodolfista && !activeNetwork")
                                                v-flex(xs12 v-if="isAvailable('field_area')")
                                                    v-select(
                                                        v-model="step.field_area"
                                                        :items='field_area'
                                                        item-value="uuid" 
                                                        item-text="name" 
                                                        :required="isRequired('field_area')"
                                                        :label='$t("Área de estudio")'
                                                        append-icon="keyboard_arrow_down" 
                                                        autocomplete
                                                        :filter="vFilter"
                                                    )
                                                v-flex(xs12 v-if="isAvailable('field_other_area')")
                                                    v-text-field(v-model='step.field_other_area' :label='$t("Otra área de estudio")')
                                                v-flex(xs12 align-center)
                                                    v-layout.pa-3(align-center hide-details)
                                                        v-checkbox(v-model="step.inschoolgroup")
                                                        p.ml-2(style="width: 90%;font-size:14px;")
                                                            | {{$t('Da clic en el cuadro si deseas ampliar información de tus estudios.')}}
                                                template(v-if="step.inschoolgroup")
                                                    v-flex(xs12 v-if="isAvailable('field_school')")
                                                        h3 {{ $t('Datos del colegio donde estudió') }}
                                                    v-flex(xs12 v-if="isAvailable('field_state')")
                                                        v-select(
                                                            v-model="step.field_state"
                                                            :items='field_state' 
                                                            :label='$t("Departamento donde está ubicado su colegio")' 
                                                            append-icon="keyboard_arrow_down" 
                                                            autocomplete
                                                            :filter="vFilter"
                                                            item-value="uuid" 
                                                            item-text="name" 
                                                            :required="isRequired('field_state')"
                                                            @input="fetchFieldCity"
                                                        )
                                                    v-flex(xs12 v-if="isAvailable('field_city')")
                                                        v-select(
                                                            v-model="step.field_city"
                                                            :items='field_city' 
                                                            :label='$t("Ciudad donde está ubicado su colegio")' 
                                                            append-icon="keyboard_arrow_down" 
                                                            autocomplete
                                                            :filter="vFilter"
                                                            item-value="uuid" 
                                                            item-text="name" 
                                                            :required="isRequired('field_city')"
                                                            @input="fetchFieldSchool"
                                                        )
                                                    v-flex(xs12 v-if="isAvailable('field_school')")
                                                        v-select(
                                                            v-model="step.field_school"
                                                            :items='field_school'
                                                            :label='$t("Nombre de su colegio")' 
                                                            append-icon="keyboard_arrow_down"
                                                            autocomplete
                                                            :filter="vFilter"
                                                            item-value="uuid" 
                                                            item-text="name" 
                                                            :required="isRequired('field_school')"
                                                        )
                                                    v-flex.mt-4(xs12 v-if="isAvailable('field_institution')")
                                                        h3 {{ $t('Datos de la Universidad donde estudió o estudia.') }}
                                                    v-flex(xs12 v-if="isAvailable('field_institution')")
                                                        v-select(
                                                            v-model="step.field_institution"
                                                            :items='field_institution'
                                                            :label='$t("Universidad donde estudió o estudia")' 
                                                            append-icon="keyboard_arrow_down"
                                                            autocomplete
                                                            :filter="vFilter"
                                                            item-value="uuid" 
                                                            item-text="name" 
                                                            :required="isRequired('field_institution')"
                                                        )
                                                    v-flex(xs12 v-if="isAvailable('field_grade')")
                                                        v-text-field(v-model='step.field_grade' :label='$t("Digita el nombre de los grados recibidos o por recibir de pregrado y/o postgrado")')
                                    
                                    template(v-if="initialData")
                                        v-divider.mt-4
                                        vue-recaptcha.full-width.d-flex.justify-center.my-2.text-xs-center(v-if="Object.keys(currentNetwork).length && currentNetwork.recaptcha" :sitekey="captchaKey" ref="recaptcha" @verify="recaptchaVerifiy")
                                        .btns.btns-center
                                            v-btn.mb-4(v-if="terms" color="primary" large @click.native="save" :loading="loadingBtn") {{ $t('Registrarse') }}
                                    v-divider.x1.primary.mb-4
                                    .btns.btns-center
                                        v-btn(small color="secondary" :to="loginTo" v-if="isNetwork(['joaquingobernador', 'sumatealfr'])") {{ $t('Inicia sesión acá') }}
                                        v-btn(small color="secondary" :to="loginTo" v-else) {{ $t('Inicia sesión aquí') }}
                                        v-btn(small v-if="Object.keys(currentNetwork).length && currentNetwork.standregister && currentNetwork.hasstand" color="secondary" @click="$refs.stand.open(currentNetwork)")
                                            v-icon.mr-1 home_filled
                                            | {{ $t('Casas de registro') }}
                                    //div.mt-4(style="width:100%")
                                        h2.secondary--text(v-if="isNetwork(['joaquingobernador'])")
                                            | {{$t('ingresá')}}&nbsp;
                                            router-link(:to="loginTo") {{$t('acá')}}&nbsp;
                                            | {{$t('si ya tenés una cuenta')}} 
                                            template(v-if="!activeNetwork") {{$t('en la red de apoyo')}} {{ this.currentNetwork.name }}
                                        h2.secondary--text(v-else-if="isNetwork(['sumatealfr'])")
                                            | {{$t('Si ya tenés una cuenta')}}, {{$t('ingresá')}}&nbsp;
                                            router-link(:to="loginTo") {{$t('acá')}}&nbsp;
                                            template(v-if="!activeNetwork") {{$t('en la red de apoyo')}} {{ this.currentNetwork.name }}
                                        h2.secondary--text(v-else)
                                            | {{$t('ingresa')}}&nbsp;
                                            router-link(:to="loginTo") {{$t('aquí')}}&nbsp;
                                            | {{$t('si ya tienes una cuenta')}} 
                                            template(v-if="!activeNetwork") {{$t('en la red')}} {{ this.currentNetwork.name }}
                    v-dialog(v-if="!activeNetwork" v-model="termsModal" max-width="600") 
                        v-card
                            v-card-text
                                v-tabs(
                                    v-model="termActive"
                                    color="white"
                                    outline
                                    slider-color="primary"
                                )
                                    v-tab(:key="0") {{$t('Términos y condiciones')}}
                                    v-tab(:key="1") {{$t('Políticas de privacidad')}} 
                                    v-tab-item(:key="0")
                                        v-card.mt-2(flat v-html="currentNetwork.terms")
                                    v-tab-item(:key="1")
                                        v-card.mt-2(flat v-html="currentNetwork.priv")
                            v-card-actions
                                .btns.btns-right
                                    v-btn(color="secondary" outline @click.native="termsModal = false") {{$t('Salir')}} 
                // Juicio a expresidentes
                template(v-else-if="isNetwork(['juicioaexpresidentes'])")
                    landing1
                // Rodolfistas
                template(v-else-if="isNetwork(['rodolfistas'])")
                    landing-rodolfistas-register(
                        :currentNetwork="currentNetwork"
                    )
        stand-list(ref="stand")
</template>

<script>

    import auth from 'mixins/auth'
    import field from 'mixins/field'
    import Vue from 'vue'
    import StandList from 'components/stand/StandList'
    import VueRecaptcha from 'vue-recaptcha'

    export default {
        mixins: [auth, field], 
        metaInfo (){
            return {
                title: this.$t('Registro')
            }
        },
        data() {
            return {
                modalBirthdate: false,
                terms: false,
                termActive: 0,
                termsModal: false,
                e1: true,
                register : 0,
                state: [],
                city: [],
                country: [],
                zones: [],
                zonei: -1,
                step : {
                    names     : '',
                    surname   : '',
                    surname2  : '',
                    username  : '',
                    rusername : '',
                    email    : '',
                    remail   : '',
                    password : '',
                    phone    : '',
                    countryid: '',
                    stateid  : '',
                    cityid   : '',
                    gender   : '',
                    birthdate: null,
                    code     : '',
                    networkid: '',
                    zones    : [],
                    document : '',
                    custom   : {},
                    optional_email: '',

                    // Campos red rodolfistas
                    inschoolgroup    : false,
                    field_state      : '',
                    field_city       : '',
                    field_school     : '',
                    field_institution: '',
                    field_grade      : '',
                    field_area       : '',
                    field_other_area : '',

                    // Grupo de WhatsApp
                    wpgroup: '',

                    // Recaptcha
                    recaptcha: ''
                },
                userid: '',
                error: false,
                errorText: '',
                loadOptions: {
                    normal: 'Registrarse',
                    load: 'Registrando...'
                },
                loadDisabled: false,
                loadState: '',
                loadCheck: false,
                loadingBtn: false,
                hasData: false,
                initialData: false,
                foundData: {},
                customForm: [],
                availableRow: [],
                loadPage: false,
                wpgroups: []
            }
        },
        watch: {
            modalBirthdate (val) {
                val && this.$nextTick(() => (this.$refs.birthdate.activePicker = 'YEAR'))
            },
            step: {
                deep: true,
                handler(){
                    
                    if(this.hasMexico && this.validateUser == 'username'){
                        
                        this.step.document  = this.step.username.toUpperCase()
                        this.step.username  = this.step.username.toUpperCase()
                        this.step.rusername = this.step.rusername.toUpperCase()
                    }
                }
            },
            wpgroupEnabled(){

                if(this.wpgroupEnabled){

                    let data = {
                        countryid: this.step.countryid,
                        stateid  : this.step.stateid,
                        cityid   : this.step.cityid,
                        gender   : this.step.gender,
                        birthdate: this.step.birthdate
                    }

                    this.$api(this, (xhr) => {
                        xhr.get('/wpgroup/register', {
                            params: {
                                networkid : this.networkid,
                                filter    : JSON.stringify(data)
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.wpgroups = data.data
                            }else{
                                this.wpgroups = []
                            }

                        }).catch(() => {})
                    })
                }
            },
            terms(val){
                this.step.recaptcha = ''
            }
        },
        computed: {
            minDate(){
                return this.$moment().subtract(100, 'years').format('YYYY-MM-DD')
            },
            maxDate(){
                let current = this.$moment()
                
                if(this.currentNetwork.adult){
                    current = current.subtract(18, 'years')
                }

                return current.format('YYYY-MM-DD') 
            },
            classes(){
                return {
                    'register'                  : !this.isNetwork(['juicioaexpresidentes', 'rodolfistas']), 
                    'grid-list-xl'              : !this.isNetwork(['juicioaexpresidentes', 'rodolfistas']), 
                    'ma-0 pa-0'                 : this.isNetwork(['juicioaexpresidentes']),
                    'ma-0 pa-0 rodolfistas-base': this.isNetwork(['rodolfistas'])
                }
            },
            phone(){
                let country = this.country.find((item) => item.uuid == this.step.countryid)
                return country ? country.tel : ''
            },
            networkid(){
                return this.step.networkid
            },
            gender(){ 
                return [
                    {
                        text: this.$t('Masculino'),
                        id: 1
                    },
                    {
                        text: this.$t('Femenino'),
                        id: 0
                    },
                    {
                        text: this.$t('Otro'),
                        id: 2
                    }
                ]
            },
            validateUser(){

                let email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                if(email.test(this.step.username)){
                    return 'email'
                }

                let phone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
                if(this.step.username.length >= 7 && phone.test(this.step.username)){
                    return 'phone'
                }

                // Solo méxico
                if(this.hasMexico){
                    let username = /(?=.*[A-z])[A-z0-9]{18}/
                    if(this.step.username.length == 18 && username.test(this.step.username.trim())){
                        return 'username'
                    }
                }

                return ''
            },

            // Existe méxico
            hasMexico(){
                return this.country.some(c => c.uuid == 'd7e944ac-67a8-52b3-a303-6c520f928d69')
            },

            // Verificar estado para busqueda de grupo de WhatsApp
            wpgroupEnabled(){

                let enabled = new Date()

                if(this.isAvailable('countryid') && !this.step.countryid){
                    enabled = false
                }

                if(this.isAvailable('stateid') && !this.step.stateid){
                    enabled = false
                }

                if(this.isAvailable('cityid') && !this.step.cityid){
                    enabled = false
                }

                if(this.isAvailable('gender') && this.step.gender === 0 ? false : !this.step.gender){
                    enabled = false
                }

                if(this.isAvailable('birthdate') && !this.step.birthdate){
                    enabled = false
                }

                return enabled
            }
        },
        created(){
            this.loadState = this.loadOptions.normal
        },
        async mounted(){

            this.loadPage = true
            await this.fetchForm()

			await this.saveCurrentRefCode()
			await this.currentRefCode()
            this.fetchCountry()

            if(this.currentNetwork.isRodolfista){

                this.fetchFieldState()
                this.fetchFieldInstitution()
                this.fetchFieldArea()
            }

            if(this.activeNetwork){
                this.terms = true
            }

            this.loadPage = false
		},
        methods: {

            noNetwork(network){
                return !this.activeNetwork && Object.keys(this.currentNetwork).length ? this.currentNetwork.alias != network : true
            },

            fetchCountry(){

                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            ab: 1, 
                            networkid: this.networkid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.country = data.data
                        }
                    }).catch(() => {})
                })
            },

            fetchState(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/state', {
                            params: {
                                countryid: this.step.countryid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.state = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchCity(){

                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/city', {
                            params: {
                                stateid: this.step.stateid,
                                ab: 1, 
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                this.city = data.data
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchZone(item, push_empty_item = true){

                this.zones = this.zonei == -1 ? [] : this.zones.slice(0, this.zonei + 1)
                this.step.zones = this.zonei == -1 ? [] : this.step.zones.slice(0, this.zonei+1)
                
                return new Promise((resolve) => {
                    this.$api(this, (xhr) => {
                        xhr.get('/zone', {
                            params: {
                                cityid: this.step.cityid,
                                parent: this.zonei == -1 ? 0 : item,
                                ab: 1,
                                networkid: this.networkid,
                                filter: JSON.stringify({
                                    status: 1
                                })
                            }
                        }).then((r) => {

                            let data = r.data
                            if(data.response){
                                if (data.data.length) {

                                    if (push_empty_item) {
                                        this.step.zones.push('')    
                                    }

                                    this.zones.push(data.data)
                                }
                            }
                            resolve()
                        }).catch(() => {
                            resolve()
                        })
                    })
                })
            },

            fetchForm() {
                this.$api(this, (xhr) => {
                    xhr.get('/network/form', {
                        params: {
                            networkid: this.networkid
                        }
                    }).then((r) => {

                        let data = r.data
                        if (data.response) {

                            let custom = data.data.custom.map((c) => {
                                    c.required   = c.required == 1 ? true : false
                                    c.visibility = c.visibility == 1 ? true : false
                                    return c
                            })

                            this.customForm = custom                                
                            this.availableRow = data.data.available == null ? [] : data.data.available

                            for(let custom of this.customForm){
                                if(custom.type == 2){
                                    Vue.set(this.step.custom, custom.uuid, [])
                                }else{
                                    Vue.set(this.step.custom, custom.uuid, '')
                                }
                            }
                        }
                    }).catch(() => {})
                })
            },

            recaptchaVerifiy(response){
                this.step.recaptcha = response
            },

            check(){

                if(this.activeNetwork && !this.terms){
                    return
                }

                let post = this._.cloneDeep(this.step)
                this.loadCheck = true

                this.$api(this, (xhr) => {
                    xhr.post('/register/check', this.$qs.stringify(post)).then(async (r) => {

                        let data = r.data
                        
                        if(!data.response){

                            this.loadCheck = false
                            if(data.message.length){
                                this.error = true
                                this.errorText = data.message
                            }else{
                                this.hasData = false
                                this.initialData = true
                            }
                        }else{
                            
                            data = data.data
                            this.hasData   = true
                            this.foundData = data

                            this.foundData.birthdate = this.$moment(this.foundData.birthdate).format('YYYY-MM-DD')

                            this.step = this._.merge(this.step, {
                                names    : this.foundData.names,
                                surname  : this.foundData.surname,
                                surname2 : this.foundData.surname2,
                                countryid: this.foundData.countryid,
                                stateid  : this.foundData.stateid,
                                cityid   : this.foundData.cityid,
                                birthdate: this.foundData.birthdate,
                                document : this.foundData.document,
                                gender   : this.foundData.gender,
                                phone    : this.foundData.phone 
                            })

                            await this.fetchState()
                            await this.fetchCity()
                            await this.fetchZone()

                            if(this.foundData.zones.length){

                                let zone  = this.foundData.zones[0]
                                let zones = this.foundData.zones.slice(1, this.foundData.zones.length)
                                this.step.zones.splice(0, 1, zone)

                                if(!zones.length){

                                    this.zonei = 0
                                    await this.fetchZone(zone)
                                }

                                let zlen = zones.length
                                for(let i = 1, j=0; i <= zlen; i++, j++){

                                    let parent = this.step.zones.slice(-1)[0]
                                    this.zonei = i

                                    this.step.zones.push(zones[j])

                                    let push_empty_item = zlen == i ? false : true
                                    await this.fetchZone(parent, push_empty_item)
                                }
                            }

                            this.loadCheck = false
                            this.initialData = true
                        }

                        if(this.validateUser == 'email'){
                            this.step.email  = this.step.username
                            this.step.remail = this.step.username
                        }

                        if(this.validateUser == 'phone'){
                            this.step.email  = this.step.username + '@wappid.com'
                            this.step.remail = this.step.username + '@wappid.com'
                            this.step.phone  = this.step.username
                        }

                        if(this.hasMexico && this.validateUser == 'username'){

                            this.step.email    = this.step.username + '@wappid.com'
                            this.step.remail   = this.step.rusername + '@wappid.com'
                            this.step.phone    = 5500000000
                            this.step.document = this.step.username
                        }

                    }).catch(() => {
                        this.loadCheck = false
                        this.hasData   = false
                    })
                })
            },

            save(){

                if(this.activeNetwork && !this.terms){
                    return
                }

                if(this.validateUser == 'phone' && this.step.optional_email){

                    let email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    if(!email.test(this.step.optional_email)){

                        this.error = true
                        this.errorText = this.$t('Ingrese un correo electrónico valido')
                        return
                    }
                }

                let post = this._.cloneDeep(this.step)
                post.zones = JSON.stringify(post.zones)

                let custom = this._.cloneDeep(this.step.custom)

                for(let c in custom){
                    if(typeof custom[c] != 'string'){
                        custom[c] = Array.isArray(custom[c]) ? custom[c] : custom[c].option
                    }
                }

                post.custom = JSON.stringify(custom)
                post.inschoolgroup = post.inschoolgroup ? 1 : 0

                this.loadingBtn = true
                this.loadCheck  = true

                this.$api(this, (xhr) => {
                    xhr.post('/register', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.loadCheck = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            
                            data = data.data
                            this.$setItem('xtoken', data.token, () => {
                                this.$router.push({path: '/home' })
                            })
                        }
                    }).catch(() => {})
                })
            },
            
			async currentRefCode(){
				this.step.code = await this.$getItem('invite')
			},

            isAvailable(tag){
                
                if(!this.availableRow.length){
                    return true
                }

                let row = this.availableRow.find((r) => r.tag == tag)

                if(!row){
                    return true
                }

                return row.enable == 1 ? true : false
            },

            isRequired(tag) {
                if(!this.availableRow.length){
                    return true
                }

                let row = this.availableRow.find((r) => r.tag == tag)

                if(!row){
                    return true
                }

                return row.hasOwnProperty('required') ? (row.required == 1 ? true : false) : true
            },

            computedDepends(el, parent){

                let depends = this.customForm.filter((c) => {
                    return c.hasOwnProperty('depends') && c.depends == el.uuid
                })

                for(let depend of depends){

                    let index = this.customForm.findIndex((c) => c.uuid == depend.uuid)
                    if(parent != null && depend.options.hasOwnProperty(parent.uuid)){
                        Vue.set(this.customForm[index], 'coptions', depend.options[parent.uuid])
                    }else{
                        Vue.set(this.customForm[index], 'coptions', [])
                    }
                }
            },

            trim(key){
                this.step[key] = this.step[key].trim()
            }
        },
        components: {
            'landing1': () => import('pages/landing/landing1'),
            'landingRodolfistasRegister': () => import('pages/landing/rodolfistas-register'),
            StandList,
            VueRecaptcha
        }
    }

</script>