export default {
    data() {
        return {
            //TODO: Es rodolfistas
            field_state      : [],
            field_city       : [],
            field_school     : [],
            field_institution: [],
            field_area       : []
        }
    },
    methods: {
        //TODO: Es rodolfista
        fetchFieldState(){

            this.$api(this, (xhr) => {
                xhr.get('/field/state').then((r) => {

                    let data = r.data
                    if(data.response){
                        this.field_state = data.data
                    }
                }).catch(() => {})
            })
        },

        fetchFieldCity(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/field/city', {
                        params: {
                            state_id: this.step.field_state
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.field_city = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        fetchFieldSchool(){

            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/field/school', {
                        params: {
                            city_id: this.step.field_city
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.field_school = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },

        fetchFieldInstitution(){

            this.$api(this, (xhr) => {
                xhr.get('/field/institution').then((r) => {

                    let data = r.data
                    if(data.response){
                        this.field_institution = data.data
                    }
                }).catch(() => {})
            })
        },

        fetchFieldArea(){

            this.$api(this, (xhr) => {
                xhr.get('/field/area').then((r) => {

                    let data = r.data
                    if(data.response){
                        this.field_area = data.data
                    }
                }).catch(() => {})
            })
        },
        
        isRequired(tag){

            if(!this.availableRow.length){
                return false
            }

            let row = this.availableRow.find((r) => r.tag == tag)

            if(!row){
                return false
            }

            return row.hasOwnProperty('required') ? (row.required == 1 ? true : false) : true
        },

        isAvailable(tag){
                
            if(!this.availableRow.length){
                return true
            }

            let row = this.availableRow.find((r) => r.tag == tag)

            if(!row){
                return true
            }

            return row.enable == 1 ? true : false
        }
    }
}